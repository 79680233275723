import * as firebase from 'firebase/app';
import 'firebase/functions';

const firebaseConfig = {
  apiKey: 'AIzaSyBeH04Z3jdUt2UqpQHS6VKI0WOd6gIZM0A',
  authDomain: 'bronze-portal-dev.firebaseapp.com',
  databaseURL: 'https://bronze-portal-dev.firebaseio.com',
  projectId: 'bronze-portal-dev',
  storageBucket: 'bronze-portal-dev.appspot.com',
  messagingSenderId: '682965830065',
  appId: '1:682965830065:web:9bbd762fc2b54a06d55590',
};

firebase.initializeApp(firebaseConfig);

export default firebase;
