import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { ContactUsInfo } from '../firebase.service';

export type ValidatedFn = (contactInfo: ContactUsInfo) => Promise<void>;

interface Props {
  validatedFn: ValidatedFn;
}

interface FormValues {
  name: string;
  email: string;
  message: string;
}

const ContactUsForm = (props: Props) => {
  const initialValues: FormValues = {
    name: '',
    email: '',
    message: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object({
        name: Yup.string()
          .max(60, 'Must be 60 characters or less')
          .required('Required'),
        email: Yup.string()
          .email('Invalid email address')
          .required('Required'),
        message: Yup.string()
          .max(200, 'Must be 200 characters or less')
          .required('Required'),
      })}
      onSubmit={(values, actions) => {
        console.log(values);
        props.validatedFn(values).then(_ => {
          actions.setSubmitting(false);
          actions.resetForm();
        });
      }}
    >
      <Form>
        <Field name="name">
          {({ field, form, meta }: { field: any; form: any; meta: any }) => (
            <div className="form-group">
              <input type="text" {...field} placeholder="Your Name" className="form-control" />
              <div className="help-block with-errors">
                {meta.touched && meta.error && meta.error}
              </div>
            </div>
          )}
        </Field>
        <Field name="email">
          {({ field, form, meta }: { field: any; form: any; meta: any }) => (
            <div className="form-group">
              <input type="text" {...field} placeholder="Your Email" className="form-control" />
              <div className="help-block with-errors">
                {meta.touched && meta.error && meta.error}
              </div>
            </div>
          )}
        </Field>
        <Field name="message">
          {({ field, form, meta }: { field: any; form: any; meta: any }) => (
            <div className="form-group">
              <textarea
                type="text"
                {...field}
                placeholder="Your Message"
                className="form-control"
              ></textarea>
              <div className="help-block with-errors">
                {meta.touched && meta.error && meta.error}
              </div>
            </div>
          )}
        </Field>
        <button type="submit" className="btn btn-common">
          Submit
        </button>
      </Form>
    </Formik>
  );
};

export default ContactUsForm;
