import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

export type ValidatedFn = (email: string) => Promise<void>;

interface Props {
  validatedFn: ValidatedFn;
}

interface FormValues {
  email: string;
}

const ProviderRegistrationForm = (props: Props) => {
  const initialValues: FormValues = {
    email: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object({
        email: Yup.string()
          .email('Invalid email address')
          .required('Required'),
      })}
      onSubmit={(values, actions) => {
        console.log(values);
        props.validatedFn(values.email).then(_ => {
          actions.setSubmitting(false);
          actions.resetForm();
        });
      }}
    >
      <Form>
        <Field name="email">
          {({ field, form, meta }: { field: any; form: any; meta: any }) => (
            <div className="form-group">
              <input type="text" {...field} placeholder="Your Email" className="form-control" />
              <div className="help-block with-errors">
                {meta.touched && meta.error && meta.error}
              </div>
            </div>
          )}
        </Field>
        <button type="submit" className="btn btn-common">
          Submit
        </button>
      </Form>
    </Formik>
  );
};

export default ProviderRegistrationForm;
