import React, { useState, useEffect } from 'react';
import { Link, Element, scroller } from 'react-scroll';
import { Navbar, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap';
import { Parallax } from 'react-parallax';

const Header = () => {
  const [scrollClass, setScrollClass] = useState('');
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, []);
  const [expanded, setExpanded] = useState(false);

  const handleScroll = () => {
    if (window.pageYOffset > 185) setScrollClass('navbar-scroll-color');
    else setScrollClass('');
  };

  const doScroll = (link: string) => {
    scroller.scrollTo(link, {
      smooth: true,
      spy: true,
      delay: 200,
      duration: 1000,
      offset: -50,
    });
  };

  return (
    <Element name="home">
      <header id="hero-area">
        <Parallax
          bgImage={require('../assets/img/cover.png')}
          strength={500}
          bgClassName="hero-plx"
        >
          <Navbar
            collapseOnSelect
            variant="dark"
            fixed="top"
            expand="md"
            className={`transparent ${scrollClass}`}
          >
            <Navbar.Brand href="#home">
              <img className="img-fluid" src="img/bronze-star-logo.png" alt="" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                <Nav.Link eventKey="home" onSelect={() => doScroll('home')}>
                  Home
                </Nav.Link>
                <Nav.Link eventKey="about-us" onSelect={() => doScroll('about-us')}>
                  About Us
                </Nav.Link>
                <Nav.Link eventKey="staffing" onSelect={() => doScroll('staffing')}>
                  Staffing
                </Nav.Link>
                <Nav.Link eventKey="contact" onSelect={() => doScroll('contact')}>
                  Contact Us
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-md-10">
                <div className="contents text-center">
                  <h2>Trusted Medical Staffing</h2>
                </div>
              </div>
            </div>
          </div>{' '}
        </Parallax>
      </header>
    </Element>
  );
};

export default Header;
