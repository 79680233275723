import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import Header from './sections/Header';
import { Events, scrollSpy } from 'react-scroll';
import AboutUs from './sections/AboutUs';
import Staffing from './sections/Staffing';
import ContactUs from './sections/ContactUs';
import Footer from './sections/Footer';
import RolesBranch from './sections/RolesBranch';
import { Modal, Button } from 'react-bootstrap';
import Providers from './sections/Providers';

function App() {
  return (
    <div className="App">
      <Header></Header>
      <AboutUs></AboutUs>
      <Staffing></Staffing>
      {/* <Providers></Providers> */}
      {/* <RolesBranch></RolesBranch> */}
      <ContactUs></ContactUs>
      <Footer></Footer>
    </div>
  );
}

export default App;
