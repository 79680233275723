import React from 'react';
import { Element } from 'react-scroll';
import { Button } from 'react-bootstrap';
import MessageModal from './MessageModal';
import ContactUsForm, { ValidatedFn } from './ContactUsForm';
import { ContactUsInfo, firebaseService } from '../firebase.service';

interface Props {}

const ContactUs: React.FC<Props> = () => {
  const [submittedShow, setSubmittedShow] = React.useState(false);
  const [submittedTitle, setSubmittedTitle] = React.useState('');
  const [submittedMessage, setSubmittedMessage] = React.useState('');
  const validated: ValidatedFn = async (contactInfo: ContactUsInfo): Promise<void> => {
    return new Promise((resolve, reject) => {
      firebaseService
        .contactUs(contactInfo)
        .then(_ => {
          setSubmittedTitle('Success');
          setSubmittedMessage("You're message has been sent. Thanks!");
          setSubmittedShow(true);
        })
        .catch(_ => {
          setSubmittedTitle('There was a problem...');
          setSubmittedMessage("You're message could not be sent.");
          setSubmittedShow(true);
        });
      resolve();
    });
  };

  return (
    <Element name="contact">
      <section id="contact" className="section" data-stellar-background-ratio="-0.2">
        <div className="contact-form">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-sm-6 col-xs-12">
                <div className="contact-us">
                  <h3>Contact us...</h3>
                  <div className="contact-address">
                    <p>2951 Bluebell Ave, Brea, CA 92821-4702</p>
                    <p className="phone">
                      Phone: <span>(714-640-4460)</span>
                    </p>
                    <p className="email">
                      E-mail: <span>(info@bronzestarmedical.com)</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6 col-xs-12">
                <div className="contact-block">
                  <ContactUsForm validatedFn={validated}></ContactUsForm>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <MessageModal
        show={submittedShow}
        onHide={() => setSubmittedShow(false)}
        title={submittedTitle}
        message={submittedMessage}
      ></MessageModal>
    </Element>
  );
};

export default ContactUs;
