import firebase from './firebase.dev';

export interface ContactUsInfo {
  name: string;
  email: string;
  message: string;
}

const fbRegisterProvider = firebase.functions().httpsCallable('registerProvider');
const fbContactUs = firebase.functions().httpsCallable('contactUs');

const registerProvider = (email: string): Promise<void> => {
  return new Promise(async (resolve, reject) => {
    fbRegisterProvider(email)
      .then(_ => {
        resolve();
      })
      .catch(error => {
        console.log(`registerProvider error: ${error}`);
        reject();
      });
  });
};

const contactUs = (info: ContactUsInfo): Promise<void> => {
  return new Promise(async (resolve, reject) => {
    fbContactUs(info)
      .then(_ => {
        resolve();
      })
      .catch(error => {
        console.log(`contactUs error: ${error}`);
        reject();
      });
  });
};

export const firebaseService = {
  registerProvider: registerProvider,
  contactUs: contactUs,
};
