import React from 'react';
import { Link, Element } from 'react-scroll';

const AboutUs = () => {
  return (
    <Element name="about-us">
      <section id="about-us" className="section">
        {/* <!-- Container Starts --> */}
        <div className="container">
          <div className="section-header">
            <h2 className="section-title">About Us</h2>
            <hr className="lines" />
            <p className="section-subtitle">
              Bronze Star Medical Staff Specialists is a woman owned service disabled veteran small
              business founded in 2012. We strive to attract and retain the highest quality medical
              and business consulting professionals and provide them with dynamic opportunities to
              work with our veterans, service members and local community. There is no greater honor
              than to serve this nation and it is our duty, at every level, to provide exceptional
              services to those that have made this defining sacrifice.
              <br />
              <br />
              Our core capabilities developed through experience are staffing in all facets of
              healthcare, medical equipment leasing and quality assurance. We are committed to
              excellence and provide medical services for optimum patient care and safety. Among our
              strongest competencies is our ability to successfully recruit across a broad and
              diverse range of employees. We service ten million dollars in government contracts
              while maintaining the highest customer and patient satisfaction ratings.
            </p>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 about-us-item">
              {/* <!-- Staffing Item Starts --> */}
              <div className="about-us-item-wrapper">
                <div className="about-us-item-img">
                  <a href="single-post.html">
                    <img src="img/about-us/crna1.jpg" alt="" />
                  </a>
                </div>
                {/* <div className="about-us-item-text">
                  <div className="meta-tags">
                    <span className="date">
                      <i className="lnr  lnr-clock"></i>2 Days Ago
                    </span>
                    <span className="comments">
                      <a>
                        <i className="lnr lnr-bubble"></i> 24 Comments
                      </a>
                    </span>
                  </div>
                  <h3>
                    <a href="single-post.html">How often should you tweet?</a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua...
                  </p>
                  <a href="single-post.html" className="btn-rm">
                    Read More <i className="lnr lnr-arrow-right"></i>
                  </a>
                </div> */}
              </div>
              {/* <!-- Staffing Item Wrapper Ends--> */}
            </div>

            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 about-us-item">
              {/* <!-- Staffing Item Starts --> */}
              <div className="about-us-item-wrapper">
                <div className="about-us-item-img">
                  <a href="single-post.html">
                    <img src="img/about-us/crna2.jpg" alt="" />
                  </a>
                </div>
                {/* <div className="about-us-item-text">
                  <div className="meta-tags">
                    <span className="date">
                      <i className="lnr  lnr-clock"></i>2 Days Ago
                    </span>
                    <span className="comments">
                      <a>
                        <i className="lnr lnr-bubble"></i> 24 Comments
                      </a>
                    </span>
                  </div>
                  <h3>
                    <a href="single-post.html">Content is still king</a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua...
                  </p>
                  <a href="single-post.html" className="btn-rm">
                    Read More <i className="lnr lnr-arrow-right"></i>
                  </a>
                </div> */}
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 about-us-item">
              {/* <!-- Staffing Item Starts --> */}
              <div className="about-us-item-wrapper">
                <div className="about-us-item-img">
                  <a href="single-post.html">
                    <img src="img/about-us/crna3.jpg" alt="" />
                  </a>
                </div>
                {/* <div className="about-us-item-text">
                  <div className="meta-tags">
                    <span className="date">
                      <i className="lnr  lnr-clock"></i>2 Days Ago
                    </span>
                    <span className="comments">
                      <a>
                        <i className="lnr lnr-bubble"></i> 24 Comments
                      </a>
                    </span>
                  </div>
                  <h3>
                    <a href="single-post.html">Social media at work</a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua...
                  </p>
                  <a href="single-post.html" className="btn-rm">
                    Read More <i className="lnr lnr-arrow-right"></i>
                  </a>
                </div> */}
              </div>
              {/* <!-- Staffing Item Wrapper Ends--> */}
            </div>
          </div>
        </div>
      </section>
    </Element>
  );
};

export default AboutUs;
