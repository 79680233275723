import React from 'react';
// import { Link } from 'react-scroll';

interface Props {}

const Footer: React.FC<Props> = () => {
  return (
    <footer>
      <div className="container">
        <div className="row">
          {/* <!-- Footer Links --> */}
          <div className="col-12">
            <div className="copyright">
              <p>
                &copy; Copyright 2020 Bronze Star Medical Inc. All rights reserved. - Designed by
                &nbsp;
                <a href="https://youtu.be/dQw4w9WgXcQ">TorchOrbit, LLC.</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
