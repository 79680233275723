import React from 'react';
import { Link, Element } from 'react-scroll';
import { Container, Row, Col, Card, Button, Modal, Form } from 'react-bootstrap';
import MessageModal from './MessageModal';
import { Parallax, Background } from 'react-parallax';
import { firebaseService } from '../firebase.service';
import ProviderRegistrationForm, { ValidatedFn } from './ProviderRegistrationForm';
import { resolve } from 'url';

const staffingSkills = [
  'EKG Monitor Technicians',
  "CRNA's",
  'MRI/CT Technicians',
  'Echo Technicians',
  'Cardiopulmonary Perfusionists',
];

const Staffing = () => {
  const [submittedShow, setSubmittedShow] = React.useState(false);
  const [submittedTitle, setSubmittedTitle] = React.useState('');
  const [submittedMessage, setSubmittedMessage] = React.useState('');
  const validated: ValidatedFn = async (email: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      firebaseService
        .registerProvider(email)
        .then(_ => {
          setSubmittedTitle('Success');
          setSubmittedMessage("You're email was successfully added to our list. Thanks!");
          setSubmittedShow(true);
        })
        .catch(_ => {
          setSubmittedTitle('There was a problem...');
          setSubmittedMessage("You're email could not be registered.");
          setSubmittedShow(true);
        });
      resolve();
    });
  };

  return (
    <Element name="staffing">
      <section id="staffing" className="section">
        <div className="container">
          <div className="section-header">
            <h2 className="section-title">Staffing</h2>
            <hr className="lines" />
            <p className="section-subtitle">
              Our excellence rests on being always responsive, always available and always focused
              on the specific customer requirements. Our team of operational and administrative
              personnel target the core needs of our customers and find workable, cost-effective
              solutions.
              <br />
              <br />
              Every Bronze Star team member who walks through your doors has been properly screened
              and credentialled, we complete a clinical assessment within the first 90 days of hire.
              We provide a credentialing packet for each team member that starts at your facility,
              to include; details of the team members job duties, primary source verifications -
              proof of valid licensure(s), certifications, health screenings.
            </p>
          </div>
          <div className="skills-section">
            <div className="list">
              {staffingSkills.map(x => {
                return (
                  <div className="skill" key={x}>
                    <i className="lnr lnr-star"></i>
                    <span className="skill-text">{x}</span>
                  </div>
                );
              })}
            </div>
            <div className="register">
              <Card className="text-center">
                <Card.Body>
                  <Card.Title>Providers: Receive Job Updates</Card.Title>
                  <Card.Text>
                    Register your email to receive updates on new job postings as they become
                    available. We will never share your email with anyone without your permission.
                  </Card.Text>
                  <ProviderRegistrationForm validatedFn={validated}></ProviderRegistrationForm>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
        <Parallax className="crna-plx" bgImage={require('../assets/img/crna.png')} strength={300}>
          <div id="crna-area"></div>
        </Parallax>
      </section>
      <MessageModal
        show={submittedShow}
        onHide={() => setSubmittedShow(false)}
        title={submittedTitle}
        message={submittedMessage}
      ></MessageModal>
    </Element>
  );
};

export default Staffing;
